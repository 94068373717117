
import React from "react";
import Layout from "../components/layout";
import "../styles/base.scss";

export default () => {

    return (
        <Layout>
            <section style={{display:"grid", alignContent:"center", textAlign:"center", height: "60vh"}}>
                <h1 style={{fontSize: "xx-large", margin:"auto"}}>Thank You!</h1>
                <p>Thank you for your email. I will revert as soon as possible!</p>
            </section>
            
        </Layout>
    )
}